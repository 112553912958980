import { useEffect, useState } from "react";
import "gantt-task-react/dist/index.css";
import {
  Container,
  Loader,
  Table,
  Button,
  Autocomplete,
  Center,
  Divider,
  Paper,
  Title,
  Text,
  Grid,
  List,
  ThemeIcon,
  Anchor,
  Modal,
  Tooltip,
  Group, SimpleGrid, Select, Space
} from "@mantine/core";
import { fetchAllServers } from "../api/api-servers"
import { ServerList } from "../utils/serverList"
import {
  IconCheckupList,
  IconServer,
  IconFileDatabase,
  IconSearch,
  IconAlertTriangle,
  IconHourglassEmpty,
  IconDatabase
} from "@tabler/icons"
import { toast } from "react-toastify"
import ls from 'localstorage-slim'
import Moment from "react-moment"
import 'moment/locale/it'
import moment from "moment"
import { useForm } from "@mantine/form"
import { openConfirmModal } from '@mantine/modals'
import api from "../api/api"
import { StatusCircle } from "../components/atoms/StatusCircle"

export function Dashboard() {

  const [trigger, setTrigger] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [servers, setServers] = useState<ServerList | null>(null)
  const [domainsName, setDomainsName] = useState<string[] | null>(null)
  const [domainSearchedInfo, setDomainSearchedInfo] = useState<any | null>(null)
  const [domainAutocompleteValue, setDomainAutocompleteValue] = useState<string>("")

  const [modalDomainsOpened, setModalDomainsOpened] = useState(false)
  const [modalChangeHostingStatusOpened, setModalChangeHostingStatusOpened] = useState(false)
  const [modalHostingList, setModalHostingList] = useState<any[] | null>(null)

  useEffect(() => {

    setIsLoading(true)

    const getAllServers = async () => {
      const servers = await fetchAllServers()
      setServers(servers)
      setIsLoading(false)
    }
    getAllServers()

  }, [trigger])

  useEffect(() => {

    // console.log(servers)

    // Aggiorno lista hostings
    let domains: any[] = []
    servers?.map((server) => {

      // Riporto eventuali errori
      server.results === "error" && toast.error(`${server.description} ha riportato il seguente errore: ${server.resultsDescription}`)

      // Recupero array server
      server.domains?.map((domain) => {
        domains = [...domains, `${domain.name} / ${server.description}`]
      })
    })

    setDomainsName(domains)

  }, [servers])

  useEffect(() => {
    // console.log(domainSearchedInfo)
  }, [domainSearchedInfo])

  const clearCache = () => {
    // Rimuovo cache nel localStorage
    ls.remove("sdServers")
    toast.success("Cache invalidata con successo, aggiorno i dati")

    // Riavvio il componente con il trigger
    setTrigger(trigger + 1)
  }

  const compareDates = (a: any, b: any) => {

    const currentDate = moment()

    // Se non arriva il dato, torno una soluzione fissa
    if (!a.created) { // @ts-ignore
      return Math.abs(currentDate)
    }

    const aDate = moment(a.created)
    const bDate = moment(b.created)

    // @ts-ignore
    return Math.abs(currentDate.diff(aDate)) - Math.abs(currentDate.diff(bDate))
  }

  const isDatePassed = (item: any) => {
    const today = moment()
    const date = moment(item)

    today.year(2000)
    date.year(2000)

    return date.isBefore(today)
  }

  const formChangeHostingStatus = useForm({
    initialValues: {
      status: '',
    },
    validate: {
      status: (value) => (!value ? 'Seleziona uno stato' : null),
    },
  })

  const changeHostingStatus = (values: any) => {
    setModalChangeHostingStatusOpened(false)
    openConfirmModal({
      title: 'Attenzione',
      children: (
        <>
          <Text size="sm" color="dimmed">
            Intervento sul server {domainSearchedInfo.server.description} / {domainSearchedInfo.server.ip} / {domainSearchedInfo.server.url}
          </Text>
          <Divider my="sm" />
          <Text size="sm">
            Lo stato dell'hosting {domainSearchedInfo.domain.name}  verrà cambiato da {getStatusString(domainSearchedInfo.domain.status)} a {getStatusString(values.status)}
          </Text>
        </>
      ),
      labels: { confirm: 'Conferma', cancel: 'Annulla' },
      onCancel: () => setModalChangeHostingStatusOpened(true),
      onConfirm: async () => {
        setIsLoading(true)
        try {

          const { data } = await api.put(`server/${domainSearchedInfo.server.id}/${domainSearchedInfo.domain.id}/${values.status}`)
          console.log('changeHostingStatus success', data)

          toast.success(`Stato dell'hosting cambiato correttamente`)
          setDomainSearchedInfo(null)
          clearCache()
          setIsLoading(false)

        } catch(err) {
          console.log('changeHostingStatus error',err)
          toast.error("Si è verificato un errore durante il cambio di stato del dominio")
          setIsLoading(false)
        }
      },
    })
  }

  const getStatusColor = (status: string) => {
    switch (status) {
      case "active":
        return "green"
      case "suspended":
        return "yellow"
      case "disabled":
        return "red"
      default:
        return "primary"
    }
  }

  const getStatusString = (status: string) => {
    switch (status) {
      case "active":
        return "Attivo"
      case "suspended":
        return "Sospeso"
      case "disabled":
        return "Disabilitato"
      default:
        return "Sconosciuto"
    }
  }

  return (
    <>
      {
        servers && domainsName && !isLoading ?
          <>
            <Container mb={100}>

              <Divider variant="dashed" my="xl" label="Ricerca dettagli hosting" labelPosition="center"/>

              <Autocomplete
                icon={<IconSearch size={18}/>}
                placeholder="Cerca un hosting"
                variant="filled"
                radius="lg"
                size="md"
                limit={20}
                data={domainsName}
                value={domainAutocompleteValue}
                onChange={(value) => {
                  setDomainAutocompleteValue(value)
                }}
                onItemSubmit={(item) => {

                  // Pulisco la stringa cercata eliminando il nome del server
                  const itemSearched = item.value.split(" / ")[0]

                  // Recupero tutta la stringa dopo il primo trattino
                  const serverSearched = item.value.split(" / ")[1]

                  // Ciclo i server
                  servers.map((server) => {
                    // Se il server non corrisponde a quello cercato, passo al successivo
                    if (server.description !== serverSearched) return

                    // Cerco il dominio
                    const domainSearched = server.domains?.filter((domain) => {
                      return domain.name === itemSearched
                    })

                    // Se trovo il dominio, setto lo stato inserendo il server e il dominio di riferimento
                    if (domainSearched && domainSearched.length > 0) {
                      const domainSearchedInfoWithServer = {
                        server: {
                          id: server.id,
                          ip: server.ip,
                          description: server.description,
                          url: server.url,
                        },
                        domain: domainSearched[0]
                      }
                      setDomainSearchedInfo(domainSearchedInfoWithServer)
                    }
                    // Resetto il valore dell'autocomplete
                    setDomainAutocompleteValue("")
                  })
                }}
              />

              {
                domainSearchedInfo &&
                <Paper mt={20} shadow="xs" radius="lg" p="lg" withBorder>
                  <Group position="apart" align="center">
                    <Title order={2}>{domainSearchedInfo.domain.name}</Title>
                    {
                      domainSearchedInfo.domain?.status &&
                        <Button
                          color={getStatusColor(domainSearchedInfo.domain.status)}
                          onClick={() => {
                            setModalChangeHostingStatusOpened(true)
                          }}
                        >
                          Cambia stato
                        </Button>
                    }
                  </Group>
                  <Divider variant="dotted" my={20}/>
                  <Grid>
                    <Grid.Col span={6}>
                      <Title order={5} mb={20}>Informazioni server</Title>
                      {
                        domainSearchedInfo.server &&
                        <List
                          spacing="xs"
                          size="sm"
                          center
                          icon={
                            <ThemeIcon color="teal" size={24} radius="xl">
                              <IconServer size="1rem"/>
                            </ThemeIcon>
                          }
                        >
                          <List.Item>{domainSearchedInfo.server.description}</List.Item>
                          <List.Item>{domainSearchedInfo.server.ip}</List.Item>
                          <List.Item>
                            <Anchor href={`https://${domainSearchedInfo.server.url}`} target="_blank">
                              {domainSearchedInfo.server.url}
                            </Anchor>
                          </List.Item>
                        </List>
                      }
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Title order={5} mb={20}>Informazioni hosting</Title>
                      {
                        domainSearchedInfo.domain &&
                        <List
                          spacing="xs"
                          size="sm"
                          center
                          icon={
                            <ThemeIcon color="teal" size={24} radius="xl">
                              <IconFileDatabase size="1rem"/>
                            </ThemeIcon>
                          }
                        >
                          <List.Item>
                            <Text>
                              Stato:&nbsp;
                            </Text>
                            <Text color={getStatusColor(domainSearchedInfo.domain.status)}>
                              {getStatusString(domainSearchedInfo.domain.status)}
                            </Text>
                          </List.Item>
                          <List.Item>
                            <Anchor href={`https://${domainSearchedInfo.domain.name}`} target="_blank">
                              {domainSearchedInfo.domain.name}
                            </Anchor>
                          </List.Item>
                          {
                            domainSearchedInfo.domain.created &&
                            <List.Item>Creato il {domainSearchedInfo.domain.created}</List.Item>
                          }
                        </List>
                      }
                    </Grid.Col>
                  </Grid>
                </Paper>
              }

            </Container>

            <Container mb={100}>

              <Divider variant="dashed" my="xl" label="Lista dei server" labelPosition="center"/>

              <Table highlightOnHover>
                <thead>
                <tr>
                  <th>Descrizione</th>
                  <th>IP</th>
                  <th>Url</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                {
                  servers?.map((server, key) => (
                    <tr key={server.description + key}>
                      <td>{server.description}</td>
                      <td>{server.ip}</td>
                      <td>
                        <Anchor href={`https://${server.url}`} target="_blank">
                          {server.url}
                        </Anchor>

                      </td>
                      <td align="right">
                        {
                          server.results !== "error" ?
                            <Button
                              radius="xl"
                              size="xs"
                              rightIcon={<IconCheckupList size={16}/>}
                              onClick={() => {
                                server.domains && setModalHostingList(server.domains)
                                setModalDomainsOpened(true)
                              }}
                            >
                              Lista hosting
                            </Button>
                            :
                            <Button
                              radius="xl"
                              size="xs"
                              disabled
                              rightIcon={<IconAlertTriangle size={16} color="yellow"/>}
                            >
                              Lista hosting
                            </Button>
                        }
                      </td>
                    </tr>
                  ))
                }
                </tbody>
              </Table>
            </Container>

            <Container mb={100}>

              <Divider variant="dashed" my="xl" label="Opzioni aggiuntive" labelPosition="center"/>

              <Button
                color={"yellow"}
                radius="xl"
                size="xs"
                rightIcon={<IconDatabase size={16}/>}
                onClick={() => clearCache()}
              >
                Invalida cache
              </Button>

            </Container>

          </>
          : isLoading ?
            <Center>
              <Container mb="xl">
                Carico la lista dei server<br/>
                <Loader variant="dots"/>
              </Container>
            </Center>
            :
            <Center>
              <Container mb="xl">
                Nessun risultato trovato o si è verificato un problema<br/>
              </Container>
            </Center>
      }

      <Modal
        opened={modalDomainsOpened}
        onClose={() => setModalDomainsOpened(false)}
        title={`Lista hosting`}
      >
        {
          modalHostingList &&
          <Text size="sm" color="teal">{modalHostingList?.length} hosting
            total{modalHostingList?.length > 1 ? 'i' : 'e'}</Text>
        }
        <Divider my="md"/>
        <List
          size="sm"
          spacing="xs"
          center
        >
          {
            modalHostingList?.map((hosting, key) => (
              <List.Item
                key={hosting.name + key}
                icon={
                  <ThemeIcon color={isDatePassed(hosting.created) ? 'red' : 'teal'} size={20} radius="xl">
                    <IconHourglassEmpty size={14}/>
                  </ThemeIcon>
                }
              >
                <StatusCircle color={getStatusColor(hosting.status)} />
                <Text mr="md">
                  {hosting.name}
                </Text>
                {
                  hosting.created &&
                  <span>
                    {
                      <Text color="dimmed">
                        <Moment format="D MMM" locale="it">
                          {hosting.created}
                        </Moment>
                        {
                          isDatePassed(hosting.created) ? <Moment format=" YYYY" add={{ days: 365 }}/> :
                            <Moment format=" YYYY"/>
                        }
                      </Text>
                    }
                  </span>
                }
              </List.Item>
            ))
          }
        </List>
      </Modal>

      <Modal
        opened={modalChangeHostingStatusOpened}
        onClose={() => {
          setModalChangeHostingStatusOpened(false)
          formChangeHostingStatus.reset()
        }}
        title={`Cambia stato hosting`}
      >
        <Text>Hosting: {domainSearchedInfo?.domain.name}</Text>
        <Text color={getStatusColor(domainSearchedInfo?.domain.status)}>Lo stato attuale è {getStatusString(domainSearchedInfo?.domain.status)}</Text>

        <Divider my="md"/>

        <form onSubmit={formChangeHostingStatus.onSubmit((values) => changeHostingStatus(values))}>
          <Select
            label="Cambia stato"
            placeholder="Seleziona uno stato"
            required
            data={[
              {
                value: "active",
                label: "Attivo",
                disabled: domainSearchedInfo?.domain.status === "active"
              },
              {
                value: "suspended",
                label: "Sospeso",
                disabled: domainSearchedInfo?.domain.status === "suspended"
              },
              {
                value: "disabled",
                label: "Disattivato",
                disabled: domainSearchedInfo?.domain.status === "disabled"
              },
            ]}
            {...formChangeHostingStatus.getInputProps('status')}
          />
          <Button
            type="submit"
            fullWidth
            mt="xl"
            loading={isLoading && true}
          >
            Conferma
          </Button>
        </form>
      </Modal>

    </>
  )

}
