import { Space, MediaQuery } from "@mantine/core"
import { MantineNumberSize } from "@mantine/styles"

interface Props {
  smallerXs: number | MantineNumberSize;
  largerXs: number | MantineNumberSize;
}

export const Spacer = ({ smallerXs, largerXs }: Props) => {
  return (
    <>
      <MediaQuery smallerThan="xs" styles={{ display: 'none' }}>
        <Space h={smallerXs} />
      </MediaQuery>

      <MediaQuery largerThan="xs" styles={{ display: 'none' }}>
        <Space h={largerXs} />
      </MediaQuery>
    </>
  )
}
