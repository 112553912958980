/*
Estensione per convertire tabelle in csv: https://chrome.google.com/webstore/detail/download-table-as-csv/jgeonblahchgiadgojdjilffklaihalj
*/

import api from "./api"
import ls from 'localstorage-slim'

// Funzione che recupera i domini da tutti i server
export const fetchAllServers = async () => {

  const lsServers = ls.get('sdServers')

  // Se i server sono già stati salvati in locale, li carico
  if (lsServers) {
    return lsServers
  } else {
    try {
      const res = await api.get('server/list')
      ls.set('sdServers', res.data.results, {ttl: 86400}) // Salvo i server in locale per 24 ore
      return res.data.results
    } catch (error) {
      console.log(error)
      return error
    }
  }

}

