import { createStyles } from "@mantine/core"

interface Props {
  color: string
}

export const StatusCircle = ({ color }: Props) => {

  const useStyles = createStyles((theme) => ({
    root: {
      display: 'inline-block',
      width: 5,
      height: 5,
      borderRadius: '50%',
      backgroundColor: theme.colors[color],
      marginRight: 10,
    },
  }))

  const { classes } = useStyles();

  return (
    <>
      <span className={classes.root}></span>
    </>
  )
}
