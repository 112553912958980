import {useState} from 'react';
import {
	createStyles,
	Header,
	Container,
	Group,
	Title,
	Text, Image,
} from '@mantine/core';
import {NavLink} from "react-router-dom";
import { Spacer } from "../atoms/Spacer"

const HEADER_HEIGHT = 60;

const useStyles = createStyles((theme) => ({
	root: {
		position: 'fixed',
		zIndex: 100,
		top: 0,
		left: 0,
		right: 0,

		[theme.fn.smallerThan('sm')]: {
			marginBottom: 30,
		},
	},

	dropdown: {
		position: 'absolute',
		top: HEADER_HEIGHT,
		left: 0,
		right: 0,
		zIndex: 101,
		borderTopRightRadius: 0,
		borderTopLeftRadius: 0,
		borderTopWidth: 0,
		overflow: 'hidden',

		[theme.fn.largerThan('sm')]: {
			display: 'none',
		},
	},

	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '100%',
	},

	logo: {
		textDecoration: 'none',
		color: theme.colors.primaryColor,
		'&, &:hover': {
			textDecoration: 'none',
		},
	},

}));

export function Navbar() {

	const {classes, cx} = useStyles();

	return (
		<>
			<Header height={HEADER_HEIGHT} className={classes.root}>
				<Container className={classes.header}>

					<Title order={4}>
						<Group>
							<Image src="./logo.png" width={26} height={26} alt="SD Server List Logo" />
							<Text
								component={NavLink}
								variant="gradient"
								gradient={{ from: 'lime', to: 'green', deg: 45 }}
								to="/"
								className={classes.logo}
							>
								Server List
							</Text>
						</Group>
					</Title>

				</Container>
			</Header>
			<Spacer smallerXs={120} largerXs={80} />
		</>
	);
}
