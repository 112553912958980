import { createStyles, Container, Group, Text } from '@mantine/core';
import { Link } from "react-router-dom"
import { appVersionComplete } from "../../utils/constants"

const useStyles = createStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    marginTop: 120,

    [theme.fn.smallerThan('sm')]: {
      marginTop: 25,
    },
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },
}));

export function FooterSimple() {

  const { classes } = useStyles();

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
          <Text size="xs">SD Server List @ Made with cherries - {appVersionComplete}</Text>
      </Container>
    </div>
  );
}
