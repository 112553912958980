import { LoadingOverlay, MantineProvider } from '@mantine/core'
import {Navbar} from "./components/organisms/Navbar"
import {Dashboard} from "./pages/Dashboard"
import { Navigate, Route, Routes } from "react-router-dom"
import {NotFound} from "./pages/NotFound"
import {FooterSimple} from "./components/organisms/Footer"
import { NotificationsProvider } from '@mantine/notifications'
import { ScrollToTop } from "./utils/scrollToTop"
import { Helmet } from "react-helmet"
import { ModalsProvider } from "@mantine/modals"

export default function App() {

	return (
		<MantineProvider
			theme={{
				colorScheme: 'dark',
				primaryColor: 'violet',
			}}
			withGlobalStyles
			withNormalizeCSS
		>
			<ModalsProvider>
				<Helmet
					titleTemplate="%s | SD Server List"
					defaultTitle="SD Server List"
				>
					<meta name="description" content="" />
				</Helmet>

				<NotificationsProvider autoClose={5000}>

					<Navbar />

					<ScrollToTop />

					<Routes>
						<Route path="/" element={<Dashboard />} />
						<Route path="*" element={< NotFound />} />
					</Routes>

					<FooterSimple />

					<LoadingOverlay
						visible={false}
						overlayBlur={5}
						style={{position: 'fixed'}}
					/>

				</NotificationsProvider>
			</ModalsProvider>
		</MantineProvider>
	);
}
